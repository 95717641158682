<template>
  <div :style="styles">
    <el-col :span="20">
      <el-menu
          style="padding: 0"
          :router='true'
          :default-active="$route.path"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b">
        <el-menu-item index="1">
          <div class="demo-image">
            <el-image
                style="width: 40px; height: 40px"
                :src="require('../assets/img/yayeguoji_logo.jpg')"></el-image>
          </div>
        </el-menu-item>
        <el-menu-item index="/home" @click="handleHomeClick">用户管理</el-menu-item>
      </el-menu>
    </el-col>
    <el-col :span="4" class="right-part">
      <div class="grid-content bg-purple">
        <i class="el-icon-user"></i>
        <el-button type="text">{{ this.name }}</el-button>
      </div>
    </el-col>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      is_login: false,
      url_path: sessionStorage.url_path || '/',
      activeIndex: '/',
      baseFontSize: 18,
      baseLineHeight: 1.8,
      name: "",
      profile: ''
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      this.activeIndex = key
      console.log(111, this.activeIndex, key, keyPath);
    },
    goPage(url_path) {
      // 已经是当前路由就没有必要重新跳转
      if (this.url_path !== url_path) {
        // 传入的参数，如果不等于当前路径，就跳转
        this.$router.push(url_path)
      }
      sessionStorage.url_path = url_path;
    },
    handleHomeClick() {
      location.reload()
    }
  },
  created() {
    this.$axios.get('https://adm.yaelongtime.com/api/employee/me',
        {}
    ).then(res => {
        this.name=res.data.name
    }).catch(error => {
      this.$message.error(error)
    })
  },
  computed: {
    styles() {
      // 根据基准字号和行高计算字体大小和行距
      const fontSize = `${this.baseFontSize}px`;
      const lineHeight = `${this.baseLineHeight * this.baseFontSize}px`;
      // 响应式布局
      return {
        'font-size': fontSize,
        'line-height': lineHeight,
        'padding-top': '-10px',
        'padding-left': '-10px',
        'padding-right': '1px',
      };
    },
  },
}
</script>

<style scoped>

</style>