<template>
  <div :style="styles">
    <el-col :span="6" style="padding-left: 40px">
      <el-input v-model="filterValue" placeholder="搜索姓名"
                @keydown.enter="search" @blur="search" @clear="refresh" clearable
                style="padding-bottom: 20px"></el-input>
    </el-col>
    <el-table
        :data="tableData"
        border
        :max-height="getMaxHeight()"
        :header-row-style="{height: '50px'}"
        style="width: 100%">
      <el-table-column
          sortable
          type="index"
          label="序号"
          width="60">
      </el-table-column>
      <el-table-column
          prop="userId"
          label="工号"
          width="180">
      </el-table-column>
      <el-table-column
          prop="name"
          label="姓名"
          width="100">
      </el-table-column>
      <el-table-column
          label="状态"
          width="100">
        <template slot-scope="scope">
          <el-switch
              v-model="scope.row.status"
              @change="handleStateChange(scope.row)"
              validate-event
              inactive-color="＃808080"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column
          prop="created"
          label="创建时间"
          width="180">
      </el-table-column>
      <el-table-column
          prop="position"
          label="岗位"
          width="180">
      </el-table-column>
      <el-table-column
          label="操作"
          width="100">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small">编辑</el-button>
          <el-button type="text" size="small" @click="handleEmployeeInfoClick(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="已绑定店铺" :visible.sync="dialogFormVisible" style="font-weight: bold;">
      <el-collapse v-for="area in areas" :key="area.id" class="area-container">
        <div class="area-header" style="padding: 10px 10px 0 0">
          <span style="padding-right: 20px">{{ area.area }}</span>
        </div>
        <el-checkbox-group v-model="selectedShops">
          <el-checkbox :label="shop.id" v-for="shop in area.storeList" :key="shop.id">{{
              shop.storeName
            }}
          </el-checkbox>
        </el-checkbox-group>
      </el-collapse>
      <el-button type="primary" plain @click="handleCancelClick">关闭</el-button>
    </el-dialog>
    <el-pagination
        class="pagination-wrapper"
        @size-change="handleSizeChange"
        @current-change="handleGetAllEmployeeInformation"
        :current-page="parseInt(pagination.page)"
        :page-sizes="pagination.pageSizeOption"
        :page-size="parseInt(pagination.page_size)"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.total_records"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "EmployeeInformation",
  data() {
    return {
      maxHeight: 500,
      selectedShops: true,
      dialogFormVisible: false,
      areas: [],
      filterValue: '',
      tableData: [],
      pagination: {
        page: 0,
        page_size: 20,
        total_records: 1,
        total_pages: 1,
        pageSizeOption: [20, 50, 100, 200]
      },
    }
  },
  methods: {
    getMaxHeight() {
      // 在这里根据屏幕大小或其他条件计算最大高度
      // 这里简单地根据窗口高度调整最大高度，你可以根据需要进行更复杂的计算
      return Math.max(window.innerHeight - 100, this.maxHeight);
    },
    handleCancelClick() {
      this.dialogFormVisible = false
    },
    //查看员工绑定的店铺信息
    handleEmployeeInfoClick(row) {
      let areas = this.areas
      let storeList = []
      let areasDict = {}
      this.$axios.get(`https://adm.yaelongtime.com/api/employee/${row.id}`).then(res => {
          console.log('已绑定的店铺信息展示', res)
        if (res.data.accounts && res.data.profiles) {
          if (areas.length === 0) {
            this.dialogFormVisible = true
            if (res.data.accounts.length > 0 && res.data.profiles.length > 0) {
              res.data.accounts.forEach(function (area) {
                areasDict = {
                  area: area.nickname + '-' + area.oid,
                  id: area.id,
                  nickname: area.nickname,
                  oid: area.oid,
                  storeList: storeList
                }
                areas.push(areasDict)
                res.data.profiles.forEach(function (store) {
                  if (area.id === store.accountId) {
                    let storeDict = {
                      id: store.id,
                      storeName: area.nickname + '-' + store.country
                    }
                    storeList.push(storeDict)
                  }
                })
                storeList = []
              })
            }
          } else {
            this.dialogFormVisible = true
          }

        } else {
          this.$message.info('无绑定店铺信息')
        }

      })
    },
    handleStateChange() {
    },
    handleSizeChange(val) {
      this.pagination.page_size = val
      this.handleGetAllEmployeeInformation(0)
      console.log(`每页 ${val} 条`);
    },
    handleClick(row) {
      this.$router.push({
        path: '/editUserManage', query: {
          userId: row.userId,
          name: row.name,
          id: row.id,
          upPath: this.$route.path
        }
      });
    },
    handleGetAllEmployeeInformation(currentPage) {
      if (currentPage === 0) {
        currentPage = this.pagination.page
      } else {
        currentPage = currentPage - 1
      }
      this.$axios.get('https://adm.yaelongtime.com/api/employee',
          {
            params: {
              page: currentPage,
              size: this.pagination.page_size,
              sort: "userid" + ',' + "asc"
            }
          }).then(res => {
        console.log(5689, res.data)
        let tableData = []
        res.data.content.forEach(function (employee) {
          let status = ''
          if (employee.status === 1) {
            status = true
          } else {
            status = false

          }
          let employeeInfoDict = {
            id: employee.id,
            userId: employee.userid,
            position: employee.position,
            name: employee.name,
            created: employee.created,
            status: status
          }
          tableData.push(employeeInfoDict)
        })
        this.tableData = tableData
        this.pagination.total_pages = res.data.totalPages
        this.pagination.total_records = res.data.totalElements
      })
    },
    search() {
      if (this.filterValue) {
        console.log(6767, this.filterValue)
        this.$axios.get(`https://adm.yaelongtime.com/api/employee`, {
          params: {
            name: this.filterValue
          }
        }).then(res => {
          console.log(676567, res.data)
          let tableData = []
          if (res.data.content) {
            res.data.content.forEach(function (employee) {
              let status = ''
              if (employee.status === 1) {
                status = true
              } else {
                status = false
              }
              let employeeInfoDict = {
                id: employee.id,
                userId: employee.userid,
                position: employee.position,
                name: employee.name,
                created: employee.created,
                status: status
              }
              tableData.push(employeeInfoDict)
            })
          } else {
            tableData = []
          }
          this.tableData = tableData
          this.pagination.total_pages = res.data.totalPages
          this.pagination.total_records = res.data.totalElements
        })
      } else {
        this.handleGetAllEmployeeInformation()
      }

    },
    refresh() {
      this.filterValue = '';
      this.search();
    },
  },
  created() {
    this.handleGetAllEmployeeInformation(0)
  }
  ,
  computed: {
    styles() {
      // 根据基准字号和行高计算字体大小和行距
      const fontSize = `${this.baseFontSize}px`;
      const lineHeight = `${this.baseLineHeight * this.baseFontSize}px`;

      // 响应式布局
      return {
        'font-size': fontSize,
        'line-height': lineHeight,
        'padding-top': '-10px',
        'padding-left': '-10px',
        'padding-right': '1px',
      };
    }
    ,
  }
  ,
}
</script>

<style scoped>

</style>