<template>
  <div class="footer" style="background-color:#545c64">
    <ul>
      <li>帮助中心</li>
      <li>意见反馈</li>
      <li>新手指南</li>
    </ul>
    <p>Copyright © admin-web.com版权所有</p>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
.footer ul {
  margin: 10px auto 16px;
  padding-top: 20px;
  width: 810px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
}

.footer ul li {
  float: left;
  width: 100%;
  margin:  10px;
  text-align: center;
  font-size: 14px;
  display: inline-block;
}


.footer p {
  text-align: center;
  font-size: 10px;
  padding-bottom: 3px;
}
.footer ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
  padding: 0;
}

.footer li {
  margin-right: 20px;
}
</style>