<template>
  <div :style="styles">
    <el-container>
      <el-header style="font-size: 25px;">编辑用户</el-header>
      <el-container style=" border: 15px solid #dee3ee;">
        <el-aside width="350px" style="border-right: 15px solid #dee3ee;">
          <el-form :label-position="labelPosition" label-width="60px" :model="formLabelAlign" style="padding-top: 40px">
            <el-form-item label="工号">
              <el-input v-model="formLabelAlign.UserId" :disabled="disabled" style="width: 250px;"></el-input>
            </el-form-item>
            <el-form-item label="姓名">
              <el-input v-model="formLabelAlign.name" style="width: 250px;"></el-input>
            </el-form-item>
          </el-form>
        </el-aside>
        <el-main>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="店铺信息" name="first">
              <el-input
                  prefix-icon="el-icon-search"
                  placeholder="按店铺搜索"
                  v-model="search_store"
                  clearable>
              </el-input>
              <div>
                <el-checkbox v-model="selectAllStores" @change="toggleAllShops" style="padding-top: 10px">全部店铺
                </el-checkbox>
                <el-collapse v-for="area in filteredAreas" :key="area.id" class="area-container">
                  <div class="area-header" style="padding: 10px 10px 0 0">
                    <span style="padding-right: 20px">{{ area.area }}</span>
                    <el-link type="primary" plain @click="toggleShopsInArea(area)">{{
                        areAllShopsSelectedInArea(area) ? '取消全选' : '全选'
                      }}
                    </el-link>
                  </div>
                  <el-checkbox-group v-model="selectedShops">
                    <el-checkbox :label="shop.id" v-for="shop in area.storeList" :key="shop.id">{{
                        shop.storeName
                      }}
                    </el-checkbox>
                  </el-checkbox-group>
                </el-collapse>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-main>
      </el-container>
      <el-footer>
        <el-row style="padding-top: 10px; text-align: center">
          <el-button plain @click="handleCancelClick">取消</el-button>
          <el-button type="primary" plain @click="handleEmployeeStoreRelationshipsClick">确定</el-button>
        </el-row>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
export default {
  name: "EditUserManage",
  data() {
    return {
      areas: [],
      merchant_boat_us : [3846794445989202, 78513730625890, 2761396446360905, 1242227323362092, 3597964035770045],
      selectedShops: [],
      selectedStoresInfo: [],
      selectAllStores: false,
      search_store: '',
      filterValue: '',
      disabled: true,
      labelPosition: 'right',
      formLabelAlign: {
        name: '',
        UserId: '',
      },
      UserId: '',
      activeName: 'first',
      selectAreasStore: []
    };
  },
  methods: {
    handleCancelClick() {
      this.$router.push({
        path: '/home'
      });
    },
    //员工店铺信息绑定
    handleEmployeeStoreRelationshipsClick() {
      let selectedStoresInfo = this.selectedStoresInfo
      let selectedShops = this.selectedShops
      console.log(6566, this.selectedShops)
      if (this.selectedShops.length > 0) {
        // 遍历原始数据
        this.areas.forEach(item => {
          const areaId = item.id;
          const userId = this.UserId
          const shopIdArray = item.storeList.filter(shop => selectedShops.includes(shop.id)).map(shop => shop.id);
          if (shopIdArray.length > 0) {
            selectedStoresInfo.push({userId, areaId, storeId: shopIdArray});
          }
        });
        // 遍历 resultData，并发送请求
        let requestData = {}
        let account = []
        let profile = []
        selectedStoresInfo.forEach(item => {
          let accountDict = {
            id: item.areaId
          }
          account.push(accountDict)
          let accountId = item.areaId
          // 构建请求参数
          item.storeId.forEach(function (store) {
            let profileDict = {
              accountId: accountId,
              id: store,
            }
            profile.push(profileDict)
          })
        });
        requestData = {
          accounts: account,
          profiles: profile,
          userid: this.formLabelAlign.UserId
        };
        // 发送 Axios POST 请求
        this.$axios.patch(`https://adm.yaelongtime.com/api/employee/${this.UserId}`, requestData)
            .then(res => {
              console.log(888, res.data)
              if (res.status === 200) {
                this.$message.success('绑定成功')
                this.$router.push({
                  path: '/home'
                });
              }
            })
            .catch(error => {
              console.error(`绑定失败`, error);
            });
      }else{
        let requestData = {}
            // 发送 Axios POST 请求
        this.$axios.patch(`https://adm.yaelongtime.com/api/employee/${this.UserId}`, requestData)
            .then(res => {
              if (res.status === 200) {
                this.$message.success('取消店铺绑定成功')
                this.$router.push({
                  path: '/home'
                });
              }
            })
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleStoreInformation() {

      this.$axios.get('https://adm.yaelongtime.com/api/account',
          {
              params: {
              size: 200,
            }
          }
      ).then(res => {
         var areas = this.areas
          console.log('店铺信息全部', res)
        var merchant_boat_us = this.merchant_boat_us
        if (res.data.content) {
          res.data.content.forEach(function (storeArea) {
            let storeList = []
            if (!storeArea.profiles) {
              console.log(storeArea.name + '没有店铺信息')
            } else {
              let storeAreaDict = {
                oid: storeArea.oid,
                nickname: storeArea.nickname,
                area: storeArea.nickname + '-' + storeArea.oid,
                storeList: storeList,
                id: storeArea.id
              }
              areas.push(storeAreaDict)
              storeArea.profiles.forEach(function (store) {
                      var shopId = merchant_boat_us.find(shop=>
                shop===store.id
              )
                  console.log('店铺shopId',shopId)
                if(!shopId){
                    let storeDict = {
                  id: store.id,
                  storeName: storeArea.nickname + '-' + store.country
                }
                storeList.push(storeDict)
                }

              })
            }
          })
        }
          // this.areas = areas
          console.log('店铺this.areas7777信息全部222', this.areas)
        this.handleEmployeeStoreRelationships()
      })
    },
    toggleAllShops() {
      if (this.selectAllStores) {
        this.selectedShops = this.getAllShopIds();
      } else {
        this.selectedShops = [];
      }
    },
    toggleShopsInArea(area) {
      const shopIdsInArea = area.storeList.map(shop => shop.id);
      if (this.areAllShopsSelectedInArea(area)) {
        this.selectedShops = this.selectedShops.filter(id => !shopIdsInArea.includes(id));
      } else {
        this.selectedShops = this.selectedShops.concat(shopIdsInArea);
      }
    },
    areAllShopsSelectedInArea(area) {

      const shopIdsInArea = area.storeList.map(shop => shop.id);
      return shopIdsInArea.every(id => this.selectedShops.includes(id));
    },
    getAllShopIds() {
       console.log('店铺this.444444', this.areas)
      return this.areas.reduce((shopIds, area) => {
        return shopIds.concat(area.storeList.map(store => store.id));
      }, []);
    },
    //查看员工绑定的店铺信息
    handleEmployeeStoreRelationships() {
      this.$axios.get(`https://adm.yaelongtime.com/api/employee/${this.UserId}`).then(res => {
          console.log('所有店铺信息展示', res)
        let selectedShops = this.selectedShops
        var areas = this.areas
          console.log('所有店铺信800息展示areas',  areas)
        let selectAreasStore = this.selectAreasStore
        let storeList = []
        let areasDict = {}
        if (res.data.accounts && res.data.profiles) {
          if (res.data.accounts.length > 0 && res.data.profiles.length > 0) {
            res.data.accounts.forEach(function (area) {
              areasDict = {
                area: area.nickname + '-' + area.oid,
                id: area.id,
                nickname: area.nickname,
                oid: area.oid,
                storeList: storeList
              }
              selectAreasStore.push(areasDict)
              res.data.profiles.forEach(function (store) {
                if (area.id === store.accountId) {
                  let storeDict = {
                    id: store.id,
                    storeName: area.nickname + '-' + store.country
                  }
                  storeList.push(storeDict)
                }
              })
              storeList = []
            })
          }

          this.selectAreasStore.forEach(selectedArea => {
            var area = areas.find(area => area.id === selectedArea.id);
            console.log('area', area)
            if (area) {
              selectedArea.storeList.forEach(selectedShop => {
                const shop = area.storeList.find(shop => shop.id === selectedShop.id);
                if (shop) {
                  selectedShops.push(shop.id); // Push the shop.id to selectedShops array
                }
                 console.log('selectedShops', selectedShops)
              });
            }
          });
        }
      })
    },
  },
  created() {
    this.formLabelAlign.UserId = this.$route.query.userId
    this.formLabelAlign.name = this.$route.query.name
    this.UserId = this.$route.query.id
    this.handleStoreInformation()
    // if(this.areas.length>0){
    //    this.handleEmployeeStoreRelationships()
    // }

  },
  computed: {
    filteredAreas() {
      if (!this.search_store) {
        return this.areas; // 如果搜索输入框无值，返回原始数据
      }
      // 使用 Array.filter() 方法筛选数据
      return this.areas.filter(area => {
        return area.nickname.includes(this.search_store)
        // console.log(66688, area.storeList.filter(store => store.storeName),)
        // return area.storeList.filter(store => store.storeName.includes(this.search_store));
        // // 根据搜索输入框的值进行筛选，这里使用 includes() 方法进行简单的包含关系筛选
      });
    },
    styles() {
      // 根据基准字号和行高计算字体大小和行距
      const fontSize = `${this.baseFontSize}px`;
      const lineHeight = `${this.baseLineHeight * this.baseFontSize}px`;

      // 响应式布局
      return {
        'font-size': fontSize,
        'line-height': lineHeight,
        'padding-top': '-10px',
        'padding-left': '-10px',
        'padding-right': '1px',
      };
    },
  },
}
</script>

<style scoped>
.el-checkbox-group {
  margin-top: 10px; /* 添加上边距以控制店铺列表与标题之间的间距 */
}

.el-header, .el-footer {
  background-color: #ffffff;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  background-color: #ffffff;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: #ffffff;
  color: #333;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
</style>